import React, {Fragment, useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";
import {toast} from "react-toastify";
import {useTranslation} from 'react-i18next';
import i18n from "../../i18n";
import {CheckboxField} from "../../components/Input/Input";
import {OverlayLoader} from "../../components/Loader/Loader";
import RouteCreator from "../RouteCreator/RouteCreator";
import VehicleSharing from "../../components/VehicleSharing/VehicleSharing";
import useServiceProvider from "../../utils/service";
import {createTemperaturesArray} from "../../utils/temperatureHelpers";
import {metersPerSecondToKilometersPerHour} from "../../utils/SIConverter";
import {getEtollStateColor, getEtollStateTooltipText} from '../../utils/vehicle';
import {getVehicleDriverOrIdentifier} from '../../utils/driver';
import type {Access} from "../../utils/interfaces/access";
import type {MapParameter} from "../../utils/interfaces/interfaces";
import type {Vehicle} from "../../utils/interfaces/vehicle";

import {IconThermometer} from "../../graphics/deviceParameters/icon-thermometer";
import {IconSpeed, IconSpeedCAN} from "../../graphics/deviceParameters/icon-speed";
import {
    IconFuel,
    IconFuelWithGPS,
    IconFuelWithPercent,
    IconFuelWithSum
} from "../../graphics/deviceParameters/icon-fuel";
import {IconMileage, IconMileageWithGPS} from "../../graphics/deviceParameters/icon-mileage";
import {IconBattery, IconBatteryVoltageGPS, IconPowerCell} from "../../graphics/deviceParameters/icon-battery";
import {IconRpm} from "../../graphics/deviceParameters/icon-rpm";
import {IconRunTime} from "../../graphics/deviceParameters/icon-run-time";
import {IconPedalPosition} from "../../graphics/deviceParameters/icon-pedal-position";
import {IconEngineLoad} from "../../graphics/deviceParameters/icon-engine-load";
import {IconEngineTemperature} from "../../graphics/deviceParameters/icon-engine-temperature";
import {IconRouteLength} from "../../graphics/deviceParameters/icon-route-length";
import {IconSignal} from "../../graphics/deviceParameters/icon-signal";

import "./DeviceListPreviewStyles.scss";

const copyCoordinatesButtonTooltipInitialState = "COPY_VEHICLE_COORDINATES_TOOLTIP";
const copyGoogleMapsPositionURLTooltipInitialState = "COPY_VEHICLE_GOOGLE_MAPS_POSITION_URL_TOOLTIP";

let timeoutId;

/**
 *
 * @param device {Device}
 * @param vehicle {Vehicle}
 * @param addVehicleRouteHistory
 * @param appVariant {AppVariant}
 * @param userRole
 * @param map {Object}
 * @param routeCreatorMode {boolean}
 * @param setRouteCreatorMode {Function}
 * @param setPolylines {Function}
 * @param setOriginDestinationCoords {Function}
 * @returns {JSX.Element}
 * @constructor
 */
export default function DeviceListPreview({
                                              device,
                                              vehicle,
                                              addVehicleRouteHistory,
                                              appVariant,
                                              userRole,
                                              map,
                                              routeCreatorMode,
                                              setRouteCreatorMode,
                                              setPolylines,
                                              setOriginDestinationCoords
                                          }) {
    const {t} = useTranslation(['common', 'Vehicles']);

    const {vehicleService} = useServiceProvider();

    const [copyVehicleCoordinatesButtonTooltip, setCopyVehicleCoordinatesButtonTooltip] = useState(t(copyCoordinatesButtonTooltipInitialState));
    const [copyVehicleGoogleMapsPositionURLButtonTooltip, setCopyVehicleGoogleMapsPositionURLButtonTooltip] = useState(t(copyGoogleMapsPositionURLTooltipInitialState));
    const [selectedParameters: MapParameter[], setSelectedParameters: Function<MapParameter[]>] = useState(vehicle.map_parameters ? vehicle.map_parameters : []);
    const [isLoading, setIsLoading] = useState(false);
    const [vehicleToShare: Vehicle | null, setVehicleToShare] = useState(null);
    const [accessList:  Access[] | null, setAccessList] = useState(null);

    const variantBusiness = appVariant === "fm";
    const variantIndividual = appVariant === "fm_lite";

    const vehicleDriverOrIdentifier = getVehicleDriverOrIdentifier(vehicle);

    useEffect(() => {
        let isMounted = true;
        if (!variantBusiness || !vehicle.vehicle_id) {
            return;
        }

        vehicleService.getAccesses(
            vehicle.vehicle_id,
            null,
            accesses => {
                if (isMounted) {
                    setAccessList(accesses);
                }
            },
            (reason) => {
                console.error('DeviceListPreview :: cannot fetch vehicle accesses', reason);
            }
        );

        return () => {
            isMounted = false;
        }
    }, [vehicleService, vehicle.vehicle_id, variantBusiness]);

    function copyCoordinatesToClipboard() {
        const el = document.createElement('textarea');
        el.value = device.lat + "," + device.lng;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopyVehicleCoordinatesButtonTooltip(t("COPIED"));
        setTimeout(() => {
            setCopyVehicleCoordinatesButtonTooltip(t(copyCoordinatesButtonTooltipInitialState));
        }, 1500)
    }

    function copyGMapsVehiclePositionToClipboard() {
        const el = document.createElement('textarea');
        el.value = "https://www.google.com/maps/search/?api=1&query=" + device.lat + "," + device.lng;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        setCopyVehicleGoogleMapsPositionURLButtonTooltip(t("COPIED"));
        setTimeout(() => {
            setCopyVehicleGoogleMapsPositionURLButtonTooltip(t(copyGoogleMapsPositionURLTooltipInitialState));
        }, 1500)
    }

    const updateVehicle = (parameters: MapParameter[]) => {
        setIsLoading(true);

        const data = {
            ...vehicle,
            map_parameters: parameters
        };

        vehicleService.updateVehicle(data, (result) => {
            console.debug('DeviceListPreview :: vehicle updated', result);
            setIsLoading(false);
        }, (error) => {
            console.error('DeviceListPreview :: cannot update vehicle', error);
            toast.error(t('CANNOT_UPDATE_VEHICLE_PARAMETERS'));
            setSelectedParameters(vehicle.map_parameters ? vehicle.map_parameters : []);
            setIsLoading(false);
        });
    }

    const debounce = (func, delay) => {
        return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
        };
    };

    const handleCheckboxChange = debounce((parameters: MapParameter[]) => {
        updateVehicle(parameters);
    }, 2000);

    const onChangeParameters = (value: boolean, parameter: string) => {
        let _parameters = [...selectedParameters];
        if (value) {
            _parameters.push(parameter);
        } else {
            _parameters = _parameters.filter(p => p !== parameter);
        }
        setSelectedParameters(_parameters);
        handleCheckboxChange(_parameters);
    }

    const localization = device.localization ? device.localization : device.lat + ', ' + device.lng;

    return <>
        <div id={"device-list-preview"} className={"device-preview"}>
            {isLoading && <OverlayLoader/>}
            {routeCreatorMode &&
                <RouteCreator device={device} onClose={() => setRouteCreatorMode(false)} setIsLoading={setIsLoading}
                              map={map} setPolylines={setPolylines}
                              setOriginDestinationCoords={setOriginDestinationCoords}/>}
            {!routeCreatorMode && <>
                <div className="device-preview-item">
                    <div className={"actions-container"}>
                        {(!userRole && variantBusiness) && <div className="vehicle-accesses">
                            <span data-tip={t("SHARE_VEHICLE")} data-place="right" onClick={e => {
                                e.stopPropagation();
                                setVehicleToShare(vehicle);
                            }}>
                                <img src={require('../../graphics/iko_share_blue.png').default} alt=""/>
                            </span>
                            <span className="amount-circle" data-tip={t('SHARE_VEHICLE_NUMBER')} data-place="right">
                                <p>{accessList === null ? '--' : accessList.length}</p>
                            </span>
                        </div>}
                    </div>
                    <span className={"device-preview-item-header"}>{t("VEHICLE")}</span>
                    <span className={"device-preview-item-description"}>{vehicle.name}</span>
                </div>
                {device.iodata ? <TemperatureRow key={"device_temperature_row" + device.id} iodata={device.iodata}
                                                 selectedParameters={selectedParameters}
                                                 onChangeParameters={onChangeParameters}/> : null}
                {!userRole && <DeviceParametersRow key={"device_parameters_row" + device.id} device={device}
                                      selectedParameters={selectedParameters} onChangeParameters={onChangeParameters}/>}
                {localization ? <LocalizationRow key={"device_localization_row" + device.id}
                                                 localization={localization}
                                                 copyGMapsVehiclePositionToClipboard={copyGMapsVehiclePositionToClipboard}
                                                 copyCoordinatesToClipboard={copyCoordinatesToClipboard}
                /> : null}
                {vehicleDriverOrIdentifier ? <DriverRow key={"device_driver_row" + device.id}
                                                        vehicleDriverOrIdentifier={vehicleDriverOrIdentifier}/> : null}
                {vehicle.puesc_register_state === 'registered' && <PuescIntegrationRow vehicle={vehicle} t={t}/>}
                {vehicle.exchanges !== null && vehicle.exchanges.length > 0 &&
                    <TransportExchangesRow key={`transport_exchanges_row${device.id}`} vehicle={vehicle}/>}
                {((!userRole && variantBusiness) || variantIndividual) &&
                    <OptionButtons addVehicleRouteHistory={() => addVehicleRouteHistory(vehicle.vehicle_id)}
                                   setMappingRouteMode={setRouteCreatorMode}/>}
            </>}
            <ReactTooltip id={"get_url_button_tooltip"} getContent={() => {
                return copyVehicleGoogleMapsPositionURLButtonTooltip
            }} place="right" effect="solid"/>
            <ReactTooltip id={"get_coordinates_button_tooltip"} getContent={() => {
                return copyVehicleCoordinatesButtonTooltip
            }} place="right" effect="solid"/>
            <ReactTooltip place="right" effect="solid" id="device-list-preview-tooltip"/>
        </div>
        {variantBusiness && vehicleToShare &&
            <VehicleSharing accessList={accessList} vehicleToShare={vehicleToShare} setVehicleToShare={setVehicleToShare}/>}
    </>
}

/**
 *
 * @param addVehicleRouteHistory {Function}
 * @param setMappingRouteMode {Function}
 * @returns {Element}
 * @constructor
 */
function OptionButtons({addVehicleRouteHistory, setMappingRouteMode}) {
    return (
        <div className="device-preview-buttons">
            <button className="button edit" onClick={() => setMappingRouteMode(true)}>{i18n.t("SET_A_ROUTE")}</button>
            <button className="button action" onClick={addVehicleRouteHistory}>{i18n.t("HISTORY_ROUTE")}</button>
        </div>
    )
}

/**
 *
 * @param iodata {IOData}
 * @param selectedParameters {MapParameter[]}
 * @param onChangeParameters {Function}
 * @returns {React.JSX.Element|null}
 * @constructor
 */
function TemperatureRow({iodata, selectedParameters, onChangeParameters}) {
    const temperatures = createTemperaturesArray(iodata);

    if (temperatures.length === 0) {
        return null;
    }

    return <div className={"device-preview-item"}>
        <span className={"device-preview-item-header"}>{i18n.t("VEHICLE_THERMOMETERS")}</span>
        <div className={"device-preview-row"}>
            <IconThermometer/>
            {temperatures.map((thermometer, index) => {
                if (!thermometer.value) {
                    return null;
                }
                return <Fragment key={`${thermometer.type}_${index}`}>
                    <ParameterCheckbox
                        parameter={`${thermometer.type}_${index}`}
                        selectedParameters={selectedParameters}
                        onChangeParameters={onChangeParameters}
                    />
                    <div data-tip={i18n.t("THERMOMETER_NO_TYPE", {
                        type: thermometer.type,
                        numberOfThermometer: (index + 1)
                    })} id={"temperature_row_" + thermometer.type + "_" + index}
                         key={"temperature_row_" + thermometer.type + "_" + index}
                         data-for="device-parameters-tooltip"
                         className="device-preview-temperature-item">{thermometer.value}</div>
                </Fragment>
            })}
        </div>
    </div>
}

/**
 *
 * @param device {Device}
 * @param selectedParameters {MapParameter[]}
 * @param onChangeParameters {Function}
 * @returns {Element}
 * @constructor
 */
function DeviceParametersRow({device, selectedParameters, onChangeParameters}) {
    const {iodata} = device;

    const powerBatteryAlarm = function () {
        if (iodata?.power_supply > 18) {
            return iodata?.power_supply < 20
        } else {
            return iodata?.power_supply < 11.6
        }
    }();
    const GPSBatterySupply = function () {
        return iodata?.power_battery < 3.9 && iodata?.power_supply < 8
    }();
    return (
        <div className={"device-preview-item can-data"}>
            <span className={"device-preview-item-header"}>{i18n.t("VEHICLE_PARAMETERS")}</span>
            <div className={"device-preview-row"}>
                {typeof device?.speed === "number" && <div className={"device-preview-can-data-item"}>
                    <IconSpeed/>
                    <ParameterCheckbox
                        parameter="speed"
                        selectedParameters={selectedParameters}
                        onChangeParameters={onChangeParameters}
                    />
                    <span className={"value"} data-tip={i18n.t("VEHICLE_SPEED_GPS")}
                          data-for="device-parameters-tooltip">
                        {Math.round(metersPerSecondToKilometersPerHour(device.speed))} km/h
                    </span>
                </div>}

                {typeof iodata?.LVCAN_speed === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconSpeedCAN/>
                        <ParameterCheckbox
                            parameter="LVCAN_speed"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("VEHICLE_SPEED_CAN")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_speed)} km/h
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_fuel_level_procent === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconFuelWithPercent/>
                        <ParameterCheckbox
                            parameter="LVCAN_fuel_level_procent"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("FUEL_LEVEL")} data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_fuel_level_procent)} %
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_fuel_level_liters === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconFuel/>
                        <ParameterCheckbox
                            parameter="LVCAN_fuel_level_liters"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("FUEL_LEVEL")} data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_fuel_level_liters)} L
                        </span>
                    </div>
                }

                {typeof iodata?.LVCAN_total_fuel_used === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconFuelWithSum/>
                        <ParameterCheckbox
                            parameter="LVCAN_total_fuel_used"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("TOTAL_FUEL_USED")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_total_fuel_used)} L
                        </span>
                    </div>}

                {/*zuzycie paliwa l/100km*/}
                {typeof iodata?.LVCAN_fuel_rate === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconFuel/>
                        <ParameterCheckbox
                            parameter="LVCAN_fuel_rate"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("FUEL_CONSUMPTION")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_fuel_rate)} l/100km
                        </span>
                    </div>
                }

                {/*całkowite zuzycie paliwa od zamontowania*/}
                {typeof iodata?.LVCAN_fuel_consumed_counted === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconFuelWithGPS/>
                        <ParameterCheckbox
                            parameter="LVCAN_fuel_consumed_counted"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("FUEL_USED_SINCE_THE_DEVICE_WAS_INSTALLED")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_fuel_consumed_counted)} L
                        </span>
                    </div>}

                {/*przebieg pojazdu*/}
                {typeof iodata?.LVCAN_vehicle_distance === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconMileage/>
                        <ParameterCheckbox
                            parameter="LVCAN_vehicle_distance"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("VEHICLE_MILEAGE")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_vehicle_distance / 1000)} km
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_total_mileage_counted === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconMileageWithGPS/>
                        <ParameterCheckbox
                            parameter="LVCAN_total_mileage_counted"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("VEHICLE_MILEAGE_SINCE_THE_DEVICE_WAS_INSTALLED")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_total_mileage_counted / 1000)} km
                        </span>
                    </div>}

                {/*napięcie akumulatora*/}
                {typeof iodata?.power_supply === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconBattery/>
                        <ParameterCheckbox
                            parameter="power_supply"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value " + (powerBatteryAlarm && "alarm")} data-tip={i18n.t("BATTERY_VOLTAGE")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.power_supply * 10) / 10} V
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_engine_RPM === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconRpm/>
                        <ParameterCheckbox
                            parameter="LVCAN_engine_RPM"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("ENGINE_SPEED")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_engine_RPM) + " " + i18n.t("RPM")}
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_engine_work_time_counted === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconRunTime/>
                        <ParameterCheckbox
                            parameter="LVCAN_engine_work_time_counted"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("ENGINE_RUN_TIME")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_engine_work_time_counted)}
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_pedal_position === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconPedalPosition/>
                        <ParameterCheckbox
                            parameter="LVCAN_pedal_position"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("GAS_PEDAL_POSITION")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_pedal_position)} %
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_engine_load === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconEngineLoad/>
                        <ParameterCheckbox
                            parameter="LVCAN_engine_load"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("ENGINE_LOAD")} data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_engine_load)} %
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_engine_temp === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconEngineTemperature/>
                        <ParameterCheckbox
                            parameter="LVCAN_engine_temp"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("ENGINE_TEMPERATURE")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_engine_temp * 10) / 10} &#x2103;
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_trip_distance === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconRouteLength/>
                        <ParameterCheckbox
                            parameter="LVCAN_trip_distance"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("TRIP_DISTANCE")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.LVCAN_trip_distance / 1000)} &#x2103;
                        </span>
                    </div>}

                {typeof iodata?.LVCAN_battery_level_percent === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconBattery/>
                        <ParameterCheckbox
                            parameter="LVCAN_battery_level_percent"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("BATTERY_LEVEL")}
                              data-for="device-parameters-tooltip">
                            {iodata.LVCAN_battery_level_percent} %
                        </span>
                    </div>}

                {typeof iodata?.power_battery === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconBatteryVoltageGPS/>
                        <ParameterCheckbox
                            parameter="power_battery"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value " + (GPSBatterySupply && "alarm")}
                              data-tip={i18n.t("THE_GPS_DEVICE_BATTERY_VOLTAGE")}
                              data-for="device-parameters-tooltip">
                            {Math.round(iodata.power_battery * 10) / 10} V
                        </span>
                    </div>}

                {typeof iodata?.battery_capacity_level === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconPowerCell/>
                        <ParameterCheckbox
                            parameter="battery_capacity_level"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("THE_BATTERY_LEVEL_OF_THE_GPS_DEVICE")}
                              data-for="device-parameters-tooltip">
                            {iodata.battery_capacity_level} %
                        </span>
                    </div>}
                {typeof iodata?.movement === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconFuel/>
                        <ParameterCheckbox
                            parameter="movement"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("MOVEMENT")}
                              data-for="device-parameters-tooltip">
                            {i18n.t(`MOVEMENT_${iodata.movement}`)}
                        </span>
                    </div>}
                {typeof iodata?.battery_level === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconPowerCell/>
                        <ParameterCheckbox
                            parameter="battery_level"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("BATTERY_STATUS")}
                              data-for="device-parameters-tooltip">
                            {i18n.t(`BATTERY_STATUS_${iodata.battery_level}`)}
                        </span>
                    </div>}
                {typeof iodata?.gsm_signal === "number" &&
                    <div className={"device-preview-can-data-item"}>
                        <IconSignal/>
                        <ParameterCheckbox
                            parameter="gsm_signal"
                            selectedParameters={selectedParameters}
                            onChangeParameters={onChangeParameters}
                        />
                        <span className={"value"} data-tip={i18n.t("GSM_SIGNAL")}
                              data-for="device-parameters-tooltip">
                            {i18n.t(`GSM_SIGNAL_${iodata.gsm_signal}`)}
                        </span>
                    </div>}
            </div>
            <ReactTooltip id={'device-parameters-tooltip'} getContent={(dataTip) => `${dataTip}`}/>
        </div>
    )
}

/**
 *
 * @param localization {string}
 * @param copyCoordinatesToClipboard {Function}
 * @param copyGMapsVehiclePositionToClipboard {Function}
 * @returns {Element}
 * @constructor
 */
function LocalizationRow({localization, copyCoordinatesToClipboard, copyGMapsVehiclePositionToClipboard}) {
    return (
        <div className="device-preview-item localization">
            <span className="actions-container">
                <span data-for='get_coordinates_button_tooltip' data-tip="" data-place="right" onClick={e => {
                    e.stopPropagation();
                    copyCoordinatesToClipboard();
                }}>
                    <img src={require('../../graphics/iko_coord_blue.png').default} alt=""/>
                </span>
                <span data-for="get_url_button_tooltip" data-tip="" data-place="right" onClick={e => {
                    e.stopPropagation();
                    copyGMapsVehiclePositionToClipboard();
                }}>
                    <img src={require('../../graphics/iko_gmaps_col.png').default} alt=""/>
                </span>
            </span>
            <span className={"device-preview-item-header"}>{i18n.t("LOCALIZATION")}</span>
            <span className={"device-preview-item-description"}>{localization}</span>
        </div>
    )
}

function DriverRow({vehicleDriverOrIdentifier}) {
    return (
        <div className={"device-preview-item"}>
            <span className={"device-preview-item-header"}>{i18n.t("DRIVER/IDENTIFIER")}</span>
            <span className={"device-preview-item-description"}>{vehicleDriverOrIdentifier}</span>
        </div>
    )
}

/**
 *
 * @param vehicle {Vehicle}
 * @param t {Function}
 * @returns {Element}
 * @constructor
 */
const PuescIntegrationRow = ({vehicle, t}) => {
    const etollColor = getEtollStateColor(vehicle);
    const tooltipText = getEtollStateTooltipText(vehicle);

    return <div className="device-preview-item">
        <span className="device-preview-item-header">{t('Vehicles:PUESC_INTEGRATION')}</span>
        <div className="device-preview-item-description puesc-integration-container">
            <div className="puesc-integration-item" style={{backgroundColor: etollColor}} data-tip={t(`Vehicles:${tooltipText}`)}>
                e-TOLL
            </div>
            <div className="puesc-integration-item"
                 style={{backgroundColor: vehicle.puesc_sent_active ? '#00D309' : '#D0D3D9'}}>
                SENT
            </div>
        </div>
    </div>
}

/**
 *
 * @param vehicle {Vehicle}
 * @constructor
 */
function TransportExchangesRow({vehicle}) {
    const exchanges = vehicle.exchanges;

    return <div className="device-preview-item">
        <span className="device-preview-item-header">{i18n.t('TRANSPORT_EXCHANGES')}</span>
        <div className="device-preview-item-description transport-exchanges-container">
            {exchanges.map(exchange => {
                return <div key={`exchange_${exchange.id}`} data-tip={i18n.t(exchange.exchange)}>
                    <img src={require(`../../graphics/transportExchanges/${exchange.exchange}.png`).default}
                         alt={i18n.t(exchange.exchange)}/>
                </div>
            })}
        </div>
    </div>
}

/**
 *
 * @param parameter {MapParameter}
 * @param selectedParameters {MapParameter[]}
 * @param onChangeParameters {Function}
 * @returns {JSX.Element}
 * @constructor
 */
const ParameterCheckbox = ({parameter, selectedParameters, onChangeParameters}) => {
    return <CheckboxField
        id={`${parameter}_checkbox`}
        value={selectedParameters.includes(parameter)}
        name={parameter}
        onChange={(value) => onChangeParameters(!value, parameter)}
        disabled={selectedParameters.length === 3 && !selectedParameters.includes(parameter)}
        withoutLabel={true}
        dataTip={i18n.t("SHOW_ON_MAP_MARKER")}
    />
}
