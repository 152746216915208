import React, {Fragment, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

import useServiceProvider from '../../utils/service';
import Table from '../../components/Table';
import {Loader} from '../../components/Loader/Loader';
import AddTransportExchanges from './parts/AddTransportExchanges';
import ShowTransportExchange from './parts/ShowTransportExchange';
import {IconInfo} from '../../graphics/icons';
import {FULL_DATE_FORMAT} from '../../utils/constants';
import type {AtlasUser} from '../../utils/interfaces/transportExchange';

const TransportExchanges = () => {
    const {t} = useTranslation(['Settings', 'common']);

    const {atlasService} = useServiceProvider();

    const [tableData: null | AtlasUser[], setTableData] = useState(null);
    const [currentRow: null | AtlasUser, setCurrentRow] = useState(null);
    const [addMode, setAddMode] = useState(false);

    const fetchAtlasUsers = useCallback(() => {
        let isMounted = true;

        atlasService.getUsers((result: AtlasUser[]) => {
            console.debug('TransportExchanges :: getUsers', result);
            if (isMounted) {
                setTableData(result);
            }
        }, (error) => {
            console.error('TransportExchanges :: getUsers', error);
        });

        return () => {
            isMounted = false;
        };
    }, [atlasService]);

    useEffect(() => {
        fetchAtlasUsers();
    }, [fetchAtlasUsers]);

    const columns = useMemo(() => [
        {
            id: 'exchange_name',
            Header: t('EXCHANGE_NAME'),
            accessor: (row: AtlasUser) => {
                return <span
                    title={typeof row.last_sync === 'number' ? `${t('LAST_SYNC')}: ${moment.unix(row.last_sync).format(FULL_DATE_FORMAT)}` : ''}>
                    {t(`common:${row.exchange}`)}
                </span>
            },
            width: 80,
        },
        {
            id: 'comment',
            Header: t('common:COMMENT'),
            accessor: (row: AtlasUser) => {
                if (!row?.comment) return '';
                const comment = row.comment;
                if (typeof comment !== 'string') return '';
                return comment.length > 50 ? comment.slice(0, 50) + '...' : comment;
            },
        }
    ], [t]);

    const defaultSortBy = useMemo(() => [{id: 'exchange_name', desc: false}], []);

    return <>
        {addMode && currentRow === null &&
            <AddTransportExchanges
                atlasService={atlasService}
                onClose={() => setAddMode(false)}
                fetchAtlasUsers={fetchAtlasUsers}/>}
        {currentRow && <ShowTransportExchange
            currentExchange={currentRow}
            setCurrentExchange={setCurrentRow}
            atlasService={atlasService}
            fetchAtlasUsers={fetchAtlasUsers}
            onClose={() => setCurrentRow(null)}/>}
        <div id="transport-exchanges">
            <div className="header">{t('common:TRANSPORT_EXCHANGES')}</div>
            <div className="description">
                <div>
                    <IconInfo/>
                    <p>{t('TRANSPORT_EXCHANGES_DESCRIPTION')}</p>
                </div>
                <button className="button action" onClick={() => setAddMode(true)}>{t('ADD_EXCHANGES')}</button>
            </div>
            <div className="table-container">
                {tableData === null && <Loader/>}
                {tableData && tableData.length === 0 &&
                    <p className="no-data">{t('NO_TRANSPORT_EXCHANGES')}</p>}
                {tableData && tableData.length > 0 && <Table
                    data={tableData}
                    columns={columns}
                    defaultSortBy={defaultSortBy}
                    onRowClick={setCurrentRow}
                    getRowProps={row => ({
                        className: row.original.id === currentRow?.id ? 'active' : ''
                    })}
                />}
            </div>
        </div>
    </>
};

export default TransportExchanges;
