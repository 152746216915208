import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import ReactTooltip from "react-tooltip";
import {useTranslation} from "react-i18next";

import DeviceListPreview from "../DeviceListPreview/DeviceListPreview";
import DeviceListItem from "../../components/DeviceListItem/DeviceListItem";
import {Loader} from "../../components/Loader/Loader";
import type {Vehicle} from "../../utils/interfaces/vehicle";
import type {Device} from  "../../utils/interfaces/device";
import type {Driver, Identifier} from "../../utils/interfaces/driver";

import "./DeviceListStyles.scss";

interface ListItem extends Vehicle {
    driver: Driver;
    identifier: Identifier;
}
export default function DeviceList({centerToMarker, selectedVehicleId, setSelectedVehicleId, deviceList, vehicleList, userData, addVehicleRouteHistory, setVehicleToShare, driverList, identifierList, appVariant, map, routeCreatorMode, setRouteCreatorMode, setPolylines, setOriginDestinationCoords}) {
    const {t} = useTranslation();

    const wrapperRef: MutableRefObject<HTMLDivElement> = useRef(null);

    const [searchValue: string, setSearchValue] = useState('');
    const [tableData: Vehicle[], setTableData] = useState(null);
    const [sortAsc: boolean, setSortAsc] = useState(true);

    useEffect(() => {
        if (routeCreatorMode) {
            return;
        }
        const handleClickOutside = (event) => {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setSelectedVehicleId(null);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        }
    }, [wrapperRef, setSelectedVehicleId, routeCreatorMode, setRouteCreatorMode]);

    useEffect(() => {
        if (deviceList === null || vehicleList === null|| identifierList === null || driverList === null) {
            return;
        }
        let td: ListItem[] = vehicleList
            .map((v: Vehicle) => ({
                ...v,
                device: deviceList.find((d: Device) => d.id === v.device_id),
                driver: driverList.find((d: Driver) => d.id === v.direct_driver_id),
                identifier: identifierList.find((i: Identifier) => i.id === v.identifier_id)
            }))
            .filter((v: ListItem) =>
                v.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                || (
                    (v.driver && (v.driver.first_name + ' ' + v.driver.last_name).toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
                    || (!v.driver && v.identifier && (v.identifier.identifier_ident.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())))
                )
            )
            .sort((a: Vehicle, b: Vehicle) => a.name.localeCompare(b.name) * (sortAsc ? 1 : -1))
        ;

        // remove selection if tableData will not contain currently selected vehicle
        if (!td.find(v => v.vehicle_id === selectedVehicleId)) {
            setSelectedVehicleId(null);
        }

        setTableData(td);
    }, [sortAsc, searchValue, deviceList, vehicleList, driverList, identifierList, selectedVehicleId, setSelectedVehicleId]);

    function selectVehicleAndCenterMapToMarker(vehicleId: number) {
        setSelectedVehicleId(vehicleId);
        centerToMarker(vehicleId);
    }

    const selectedVehicle = vehicleList?.find((vehicle) => vehicle.vehicle_id === selectedVehicleId);
    const userRole = userData.type === "user";

    useEffect(() => {
        setTimeout(() => {
            ReactTooltip.rebuild();
        }, 0);
    });

    return (
        <div id={"devices-list"} ref={wrapperRef}>
            {/*<ReactTooltip place="right" effect="solid"/>*/}
            <div className="search-n-sort-container">
                <input type="text" value={searchValue} onChange={e => {
                    setSearchValue(e.target.value);
                }} placeholder={t('SEARCH')}/>
                <span className={"sorter " + (sortAsc ? "asc" : "desc")}
                      data-tip={sortAsc ? t('SORT_DESC') : t('SORT_ASC')} onClick={() => {
                    setSortAsc(!sortAsc);
                }}><span className="icon"/></span>
                {/*{ sortAsc && <span className="sorter asc" data-tip={t('SORT_DESC')} onClick={() => { setSortAsc(!sortAsc); }}><span className="icon"/></span>}*/}
                {/*{!sortAsc && <span className="sorter desc" data-tip={t('SORT_ASC')} onClick={() => { setSortAsc(!sortAsc); }}><span className="icon"/></span>}*/}
            </div>
            <div className={"device-list"} onClick={() => setSelectedVehicleId(null)}>
                {(tableData === null) && <Loader/>}
                {(tableData !== null) && tableData.map(vehicle => {
                    if (vehicle.device_id && vehicle.active) {
                        return (
                            <DeviceListItem key={"device_item_" + vehicle.device_id}
                                            device={vehicle.device}
                                            vehicle={vehicle}
                                            driverList={driverList}
                                            vehicleSelected={selectedVehicleId === vehicle.vehicle_id}
                                            selectVehicle={selectVehicleAndCenterMapToMarker}
                                            identifierList={identifierList}
                            />
                        )
                    }
                    return null;
                })}
            </div>
            {selectedVehicle && <div className={"divider"}/>}
            {selectedVehicle && deviceList !== null && vehicleList !== null &&
                <DeviceListPreview key={`device_list_preview_${selectedVehicle.vehicle_id}`}
                                   device={deviceList.find(device => device.id === selectedVehicle.device_id)}
                                   vehicle={vehicleList.find(vehicle => vehicle.vehicle_id === selectedVehicleId)}
                                   addVehicleRouteHistory={addVehicleRouteHistory}
                                   setVehicleToShare={setVehicleToShare} appVariant={appVariant}
                                   userRole={userRole}
                                   map={map}
                                   routeCreatorMode={routeCreatorMode}
                                   setRouteCreatorMode={setRouteCreatorMode}
                                   setPolylines={setPolylines}
                                   setOriginDestinationCoords={setOriginDestinationCoords}
                />}
        </div>
    )
}
